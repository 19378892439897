
     @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");
     html,
		body {
			margin:0; padding:20px; width:100%; height:100%;
		}
		#chat {
            // display: flex;
            // height: 100vh;
            // overflow: hidden;
            position:absolute;
            height:100%;
            width:100%;
            overflow: hidden;
            overflow-y: auto;
        }
        .chatBox {
			width: 100%;
            height: 100%;
			background-color: #fff;
            display: flex;
            flex-direction: column;
            // box-sizing: content-box;
			// border-radius: 0.625rem;
            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.5rem;
                height:3rem;
                background-color:#1989fa;
            }
            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                // flex:1;
                height: calc(100% - 12rem);
                .chatInfo {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    box-sizing: border-box;
                }
            }
            .chatBox-bottom {
                // height: 10rem;
                padding-top: 1rem;
            }
		}
		.chatBox-top-imgBox {
			margin-left: 1.5rem;
		}
 
		.chatBox-top-text {
			margin-left: 1.25rem;
			font-size: 1rem;
			color: #fff;
		}
 
		
 
		.chatBox-textarea {
			width: 100%;
            border: solid 1px #eee;
		}

		
		.gpt-message-box {
			width: 100%;
			margin-bottom: 6px;
			display: flex;
			flex-direction: row;
		}
		.user-box-img {
			display: flex;
		}
 
		.chat-message {
			margin: 0 1.25rem;
		}
 
		.chat-message-name {
			font-size: 0.875rem;
			color: #888;
			display: flex;
			flex-direction: row;
		}
 
		.nowDate {
			margin: 0 0.625rem;
		}
 
		#chat .chat-message-text {
			margin-top: 0.85125rem;
            max-width: 20rem;
            padding:0.5rem 1rem;
            background-color: #E8E8E8;
            border-radius: 0.5rem;
            float: left;
            table-layout: fixed;
            word-break: break-all;
            overflow: hidden;
		}
 
		#chat .chat-message-text span{
			font-size: 0.9375rem;
			line-height: 1.5;
		}
        #chat .chat-message-text span-code{
			font-size: 0.9375rem;
			line-height: 1;
		}
		#chat .my-message-box {
			flex-direction: row-reverse;
		}
		#chat .chat-message-name1 {
			flex-direction: row-reverse;
		}
		#chat .chat-message-text1 {
			float: right;
		}
     
